import axios from 'axios';
import { clerk } from '../../utils/clerk';
import domainStore from '../../stores/domain';

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api'
    : 'https://app-aida-prod-westeurope-2-dgbwezabbpfxfegb.westeurope-01.azurewebsites.net/api';

const client = axios.create({
  baseURL,
});

// Define your interceptor
client.interceptors.request.use(
  async (config: any) => {
    // Update auth headers
    const activeDomain = domainStore.getState().domain;
    const token = (await clerk.session!.getToken()) as string;
    config.headers.authorization = `Bearer ${token}`;
    config.headers.domain = activeDomain;
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  },
);

export const proxyClient = client;

export type UserDomainsResponse = Array<{
  domainId: string;
  title: string;
  description: string;
  redirectUrl: string;
}>;

export const getUserDomains = async () => {
  const response = await client.get(`/domains`);

  return (response.data as UserDomainsResponse) || ([] as UserDomainsResponse);
};
