import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Sheet, Table } from '@mui/joy';
import { useMemo } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Spinner from '../../components/dynamicSpinner';
import NoData from '../../components/noData';
import { useInsightBalanceDrilldown } from '../../hooks/insights/useInsightBalanceDrilldown';
import { AmountIndicator, CompletenessCell } from '../completeness-drilldown/table';
import { getRgsCode } from '../../assets/rgsCodes';

type InsightDrilldownBalanceTableProps = {
  division: string;
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
  showBalance: boolean;
};

export const YearIcon = (props: { value: boolean }) => {
  const { value } = props;
  switch (value) {
    case true:
      return <CheckCircleIcon className="w-5 text-green-500" />;
    default:
      return <XCircleIcon className="w-5 text-yellow-500" />;
  }
};

export const BalanceInsightTable = ({ division, monthEnd, monthStart, yearEnd, yearStart, showBalance }: InsightDrilldownBalanceTableProps) => {
  const drilldownQuery = useInsightBalanceDrilldown(division, {
    monthEnd: monthEnd + 1,
    monthStart: monthStart + 1,
    yearEnd,
    yearStart,
  });

  const rows = useMemo(() => {
    if (!drilldownQuery.isSuccess) {
      return [];
    }
    const data = drilldownQuery.data;
    if (!data) {
      return [];
    }
    return data.rows;
  }, [drilldownQuery.data]);

  if (drilldownQuery.isLoading) {
    return <Spinner />;
  }

  if (!drilldownQuery.data?.rows.length) {
    return <NoData />;
  }

  if (!drilldownQuery.isSuccess) {
    return <NoData label="Er ging iets mis met de data ophalen" />;
  }

  return (
    <Sheet
      className="OrderTableContainer"
      variant="outlined"
      sx={{
        'width': '100%',
        'borderRadius': 'sm',
        'flexShrink': 1,
        'overflow': 'auto',
        'minHeight': 0,
        'maxHeight': '60vh',
        '--TableCell-height': '3rem',
        '& tr': {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      }}
    >
      <Table aria-label="basic table" stickyHeader stickyFooter hoverRow>
        <thead>
          <tr>
            <th>RGS Beschrijving</th>
            <th>RGS Code</th>
            {drilldownQuery.data.range.map((yearMonth) => {
              // Get month label in dutch
              const month = new Date(yearMonth.year, yearMonth.month - 1).toLocaleString('nl-NL', { month: 'short' });
              const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
              return (
                <th
                  key={`${yearMonth.year}-${yearMonth.month}`}
                  style={{
                    width: `${70 / drilldownQuery.data.range.length}%`,
                  }}
                >
                  {capitalizedMonth}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const rgsDetails = getRgsCode(row.rgsCode);
            return (
              <tr key={row.id}>
                <td>{rgsDetails?.descriptionShort ?? ''}</td>
                <td>{row.rgsCode}</td>
                {drilldownQuery.data.range.map((yearMonth) => {
                  const data = row.triggers[yearMonth.year][yearMonth.month];

                  if (showBalance) {
                    return (
                      <td key={`${yearMonth.year}-${yearMonth.month}`}>
                        <AmountIndicator outlier={data.triggered} value={data.balance} disabled={false} />
                      </td>
                    );
                  }

                  // Disabled if in the future
                  const now = new Date();
                  now.setFullYear(yearMonth.year);
                  now.setMonth(yearMonth.month - 1);

                  const isFuture = now > new Date();
                  let color = 'success';
                  if (data.triggered || isFuture) {
                    color = 'warning';
                  }

                  let icon = 'xmark';
                  if (!data.triggered && !isFuture) {
                    icon = 'check';
                  }

                  return (
                    <td key={`${yearMonth.year}-${yearMonth.month}`}>
                      <CompletenessCell key={`${yearMonth.year}-${yearMonth.month}`} icon={icon as any} color={color as any} disabled={isFuture} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Sheet>
  );
};
