import { CircularProgress, Sheet } from '@mui/joy';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { formatter } from '../../../translations/numberFormatter';
import useOutstandingReceivablesByAdministration from '../../../hooks/usePendingInvoicesPerAdministration';

type OutstandingReceivablesByAdministrationTableProps = {
  administrationId: string;
  lineType: string;
  label: string;
};

export const OutstandingReceivablesByDivisionTable = (props: OutstandingReceivablesByAdministrationTableProps) => {
  const { administrationId, lineType, label } = props;
  const invoiceQuery = useOutstandingReceivablesByAdministration({
    administrationId,
    lineType,
  });

  const columns: GridColDef[] = [
    {
      field: 'invoiceNumber',
      headerName: 'Factuur nummer',
      flex: 1,
    },
    {
      field: 'accountName',
      headerName: 'Debiteur',
      flex: 1,
    },
    {
      field: 'invoiceAmount',
      headerName: 'Openstaand bedrag',
      flex: 1,
      valueFormatter: ({ value }) => formatter.format(-(value as number)),
    },
    {
      field: 'description',
      headerName: 'Beschrijving',
      flex: 2,
    },
    {
      field: 'invoiceDate',
      headerName: 'Factuurdatum',
      flex: 1,
      valueFormatter: ({ value }) => new Date(value as string).toLocaleDateString(),
    },
  ];

  if (invoiceQuery.isLoading) {
    return <CircularProgress />;
  }

  if (invoiceQuery.isError) {
    console.error(invoiceQuery.error);
    return <div>Error</div>;
  }

  const rows = invoiceQuery.data;

  return (
    <Sheet>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(params) => params.invoiceNumber}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10 },
          },
        }}
      />
    </Sheet>
  );
};
