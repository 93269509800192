import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ListItemDecorator, Modal, ModalClose, ModalDialog } from '@mui/joy';
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import { DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import Form from './form';
import Overview from './overview';

type InsightsCheckModalProps = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

export type Check = {
  ID: string;
  RgsCodes: string;
};

export default function CheckModal(props: InsightsCheckModalProps) {
  const { isOpen, setOpen } = props;
  const [selectedCheck, setSelectedCheck] = useState<Check | null>(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!showForm) setSelectedCheck(null);
  }, [showForm]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog>
        <ModalClose variant="plain" />
        <DialogContent>
          <Tabs aria-label="Basic tabs" defaultValue={0}>
            <TabList>
              <Tab>Overzicht</Tab>
              <Tab>
                <ListItemDecorator>
                  <AddRoundedIcon />
                </ListItemDecorator>
                Aanmaken
              </Tab>
            </TabList>
            <TabPanel value={0}>
              <Overview />
            </TabPanel>
            <TabPanel value={1}>
              <Form selectedCheck={selectedCheck} cancel={() => setOpen(false)} />
            </TabPanel>
          </Tabs>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}
