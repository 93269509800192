import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Autocomplete, Box, Button, Divider, FormControl, Input, Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PostBalanceCheck } from '../../../services/api/insights/postBalanceCheck';

type BalanceChekSubFormProps = {
  onChange: (checkData: Partial<PostBalanceCheck>[]) => void;
};

export const BalanceCheckSubForm = ({ onChange }: BalanceChekSubFormProps) => {
  const [state, setState] = useState<Partial<PostBalanceCheck>[]>([{}]);

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  return (
    <Stack direction={'column'} gap={3}>
      <Stack>
        <Typography>Stel triggers in</Typography>
        <Typography variant="body2">Als één of meer checks triggeren, dan komt er een X te staan in het overzicht</Typography>
      </Stack>
      <Stack direction={'column'} gap={1}>
        {state.map((check, index) => {
          return (
            <>
              {index > 0 && <p>of</p>}
              <Stack direction={'row'} gap={2} key={index} justifyContent={'space-between'}>
                <Stack direction={'column'} gap={1}>
                  <Button
                    variant="plain"
                    disabled={index === 0}
                    onClick={() => {
                      const newState = [...state].filter((_, i) => i !== index);
                      setState(newState);
                    }}
                    sx={{ padding: 0 }}
                  >
                    <RemoveCircleRoundedIcon />
                  </Button>
                  <Box />
                </Stack>

                <FormControl>
                  <Input disabled placeholder={`Balans ${check.rgsCode ?? ''}`} />
                </FormControl>

                {/* Operator */}
                <FormControl>
                  <Autocomplete
                    placeholder={'Selecteer operator'}
                    onChange={(_, value) => {
                      const newState = [...state];
                      if (newState[index].operator === value.key) {
                        return;
                      }
                      newState[index].operator = value.key;
                      setState(newState);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.key === value.key;
                    }}
                    value={check.operator as any}
                    options={[
                      {
                        key: '<',
                        label: '< Kleiner dan',
                      },
                      {
                        key: '>',
                        label: '> Groter dan',
                      },
                      {
                        key: '=',
                        label: '= Gelijk aan',
                      },
                      {
                        key: '!=',
                        label: '!= Niet gelijk aan',
                      },
                      {
                        key: '>=',
                        label: '>= Groter dan of gelijk aan',
                      },
                      {
                        key: '<=',
                        label: '<= Kleiner dan of gelijk aan',
                      },
                    ]}
                  ></Autocomplete>
                </FormControl>

                <Stack direction={'column'} gap={1}>
                  <Stack direction={'row'} gap={5} justifyContent={'space-between'}>
                    <Input
                      placeholder={'min'}
                      fullWidth
                      type="number"
                      onChange={(e) => {
                        const newState = [...state];
                        const newValue = parseInt(e.target.value);
                        newState[index].amount = newValue;
                        setState(newState);
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </>
          );
        })}

        <Stack alignItems={'end'}>
          <Button
            variant="plain"
            endDecorator={<AddCircleRoundedIcon />}
            onClick={() => {
              setState([...state, {}]);
            }}
          >
            Regel toevoegen
          </Button>
        </Stack>
        <Divider />
      </Stack>
    </Stack>
  );
};
