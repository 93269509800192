import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import InfoIcon from '@mui/icons-material/Info';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import { Alert, Box, ListItemDecorator, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader';
import { PendingReceivablesDashboard } from './PendingInvoicesDashboard';

const OUTGOING_INVOICE_LINE_TYPE = '20';
const OUTGOING_LABEL = 'verkoop';

const INCOMING_INVOICE_LINE_TYPE = '22';
const INCOMING_LABEL = 'inkoop';

export const PendingInvoicesDashboard = () => {
  const params = useParams();
  const { tab } = params;
  const navigate = useNavigate();

  const changeTab = (tab: string) => {
    let path = window.location.pathname;
    let pathArray = path.split('/');
    pathArray[pathArray.length - 1] = tab; // Change the last parameter
    const newPath = pathArray.join('/');

    navigate(newPath); // Use { replace: true } if you want to replace the current entry
  };

  return (
    <Stack direction="column" gap={3}>
      <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
        {`Dit dashboard is in ontwikkeling`}
      </Alert>
      <PageHeader>Openstaande facturen (ouder dan 2 maanden)</PageHeader>

      <Tabs
        value={tab}
        onChange={(_, newTab) => {
          if (!newTab) return;
          changeTab(newTab as string);
        }}
        sx={{
          borderRadius: 'sm',
          boxShadow: 'sm',
          overflow: 'auto',
        }}
      >
        <TabList sx={{ py: '.5rem' }}>
          <Tab variant="plain" color="neutral" value={'incoming'}>
            <ListItemDecorator>
              <ArchiveRoundedIcon />
            </ListItemDecorator>
            Inkoop
          </Tab>
          <Tab variant="plain" color="neutral" value={'outgoing'}>
            <ListItemDecorator>
              <UnarchiveRoundedIcon />
            </ListItemDecorator>
            Verkoop
          </Tab>
        </TabList>

        <Box sx={{ py: '1rem' }}>
          <TabPanel value="outgoing">
            <PendingReceivablesDashboard lineType={OUTGOING_INVOICE_LINE_TYPE} label={OUTGOING_LABEL} />
          </TabPanel>
          <TabPanel value="incoming">
            <PendingReceivablesDashboard lineType={INCOMING_INVOICE_LINE_TYPE} label={INCOMING_LABEL} />
          </TabPanel>
        </Box>
      </Tabs>
    </Stack>
  );
};
