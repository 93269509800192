import client from '../../../utils/axiosClient';

export type PostBalanceCheck = {
  administrationId: string;
  rgsCode: string;
  operator: string;
  amount: number;
};

export const postBalanceCheck = async (checks: PostBalanceCheck[]) => {
  const response = await client.post(`/api/insights/checks/balance`, checks);
  return response.data;
};
