import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/dynamicSpinner';
import NoData from '../../components/noData';
import { SortDirection, ThSort } from '../../components/tables/thSort';
import { useInsightBalanceOverview } from '../../hooks/insights/useInsightOverview';

const PAGE_SIZE = 13;

type Props = {
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
  divisionSearch: string;
  userID?: string | null;
  filterDivisionsWithoutTriggers: boolean;
};

enum SortOptions {
  name = 'name',
}

export const InsightOverviewBalanceTable = (props: Props) => {
  const { divisionSearch, userID, yearStart, yearEnd, monthStart, monthEnd } = props;
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<SortOptions>(SortOptions.name);
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.asc);
  const navigate = useNavigate();
  const overviewQuery = useInsightBalanceOverview({
    yearStart,
    yearEnd,
    monthStart: monthStart + 1,
    monthEnd: monthEnd + 1,
    userID,
  });

  useEffect(() => {
    setPage(0);
  }, [userID, divisionSearch, props.filterDivisionsWithoutTriggers]);

  const onRowClick = (id: string) => {
    navigate({
      pathname: `/administrations/${id}/insights`,
    });
  };

  const rows = useMemo(() => {
    if (!overviewQuery.isSuccess || !overviewQuery.data?.rows.length) {
      return [];
    }

    const filtered = overviewQuery.data.rows.filter((item) => {
      if (!item.administrationName?.toLowerCase().includes(divisionSearch.toLowerCase())) {
        return false;
      }

      let show = true;
      if (props.filterDivisionsWithoutTriggers) {
        show = false;
        for (const yearMonth of overviewQuery.data.range) {
          if (item.triggers[yearMonth.year][yearMonth.month]) {
            show = true;
            break;
          }
        }
      }

      return show;
    });

    return filtered.sort((item1, item2) => {
      if (sort === SortOptions.name) {
        if (sortDir === SortDirection.asc) {
          return item1.administrationName.localeCompare(item2.administrationName);
        }
        if (sortDir === SortDirection.desc) {
          return item2.administrationName.localeCompare(item1.administrationName);
        }
      }

      return 0;
    });
  }, [overviewQuery.data, overviewQuery.isSuccess, divisionSearch, sort, sortDir, props.filterDivisionsWithoutTriggers]);

  if (overviewQuery.isLoading) {
    return <Spinner />;
  }

  if (!overviewQuery.data?.rows.length) {
    return <NoData />;
  }

  if (!overviewQuery.isSuccess) {
    return <NoData label="Er ging iets mis met de data ophalen" />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          maxHeight: '80vh',
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            '--TableCell-height': '3rem',
            '& tr': {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }}
        >
          <thead>
            <tr>
              <ThSort
                style={{ width: '20%' }}
                label="Administratie"
                setSort={setSort}
                sort={sort}
                sortDir={sortDir}
                setSortDir={setSortDir}
                sortKey={SortOptions.name}
              />
              {overviewQuery.data.range.map((yearMonth) => {
                // Get month label in dutch
                const month = new Date(yearMonth.year, yearMonth.month - 1).toLocaleString('nl-NL', { month: 'long' });
                return (
                  <th
                    style={{
                      width: `${70 / overviewQuery.data.range.length}%`,
                    }}
                  >
                    {month}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              const now = new Date();
              return (
                <tr
                  key={row.administrationId}
                  onClick={() => {
                    onRowClick(row.administrationId);
                  }}
                >
                  <td>{row.administrationName}</td>
                  {overviewQuery.data.range.map((yearMonth) => {
                    const triggered = row.triggers[yearMonth.year][yearMonth.month];

                    // Disabled if in the future
                    now.setFullYear(yearMonth.year);
                    now.setMonth(yearMonth.month - 1);

                    const isFuture = now > new Date();
                    let color = 'success';
                    if (triggered) color = 'warning';
                    if (isFuture) color = 'neutral';
                    let icon = <CloseRoundedIcon color={color as any} />;
                    if (!triggered && !isFuture) icon = <CheckRoundedIcon color={color as any} />;

                    return (
                      <td key={`${yearMonth.year}-${yearMonth.month}`} className="text-center">
                        {icon}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(overviewQuery.data.rows.length / PAGE_SIZE)}
          color="primary"
          onChange={(_, page) => setPage(page - 1)}
          page={page + 1}
        />
      </Box>
    </>
  );
};
