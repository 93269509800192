import client from '../../utils/axiosClient';

const getCompletenessV2 = async (division: string, year: number, month: number, checkId: string) => {
  const response = await client.get(`/api/completeness/${division}/transactions`, {
    params: {
      year,
      month,
      checkId,
    },
  });
  return response.data as {
    TransactionID: string;
    TransactionAmount: number;
    TransactionDescription: string;
    GeneralLedgerCode: string;
    GeneralLedgerDescription: string;
    automated: number;
    invoiceNumber: number | null;
    entryID: string | null;
    entryNumber: number | null;
    JournalCode: string | null;
    journalDescription: string | null;
    rgsL4: string | null;
  }[];
};

export default getCompletenessV2;
