import DeleteForever from '@mui/icons-material/DeleteForever';
import Edit from '@mui/icons-material/Edit';
import MoreVert from '@mui/icons-material/MoreVert';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { FormControl, IconButton, Input, Table, Typography } from '@mui/joy';
import Dropdown from '@mui/joy/Dropdown';
import ListDivider from '@mui/joy/ListDivider';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Sheet from '@mui/joy/Sheet';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import { useBalanceChecksByAdministration } from '../../hooks/insights/useInsightChecksByAdministration';
import useDivision from '../../hooks/useDivision';
import { deleteBalanceCheck } from '../../services/api/insights/deleteBalanceCheck';
import { TBalanceCheck } from '../../services/api/insights/getInsightBalanceDrilldown';
import { patchBalanceCheck } from '../../services/api/insights/patchBalanceCheck';
import { getRgsCode } from '../../assets/rgsCodes';

type Props = {
  administrationId: string;
};

export const BalanceCheckEditor = ({ administrationId }: Props) => {
  const [loading, setLoading] = useState(false);
  const balanceChecksByAdministrationQuery = useBalanceChecksByAdministration(administrationId);
  const divisionQuery = useDivision(administrationId);
  const [insightCheckToUpdate, setInsightCheckToUpdate] = useState<TBalanceCheck | null>();

  const updateInsightCheck = async () => {
    if (!insightCheckToUpdate) {
      return;
    }
    setLoading(true);
    await patchBalanceCheck(insightCheckToUpdate);
    await balanceChecksByAdministrationQuery.refetch();
    setInsightCheckToUpdate(null);
    setLoading(false);
  };

  const onClickDelete = async (id: string) => {
    setLoading(true);
    await deleteBalanceCheck(administrationId, id);
    await balanceChecksByAdministrationQuery.refetch();
    setLoading(false);
  };

  const rows = balanceChecksByAdministrationQuery.data || [];

  if (!balanceChecksByAdministrationQuery.isSuccess) {
    return <Spinner />;
  }

  console.log(insightCheckToUpdate);

  return (
    <div key={`balance_check_editor_${administrationId}`}>
      <Typography component="h2" id="close-modal-title" level="h4" textColor="inherit" fontWeight="lg" marginBottom={'1rem'}>
        {`Balans checks configureren voor ${divisionQuery.data?.Description}`}
      </Typography>

      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter>
          <thead>
            <tr>
              <th>RGS code</th>
              <th>RGS Beschrijving</th>
              <th>Operator</th>
              <th>Waarde</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.rgsCode}</td>
                  <td>{getRgsCode(row.rgsCode)?.descriptionShort ?? ''}</td>
                  <td>{row.operator}</td>
                  <td>
                    <FormControl>
                      <Input
                        key="amount"
                        defaultValue={row.amount}
                        type="number"
                        disabled={row.id !== insightCheckToUpdate?.id || loading}
                        variant="soft"
                        onChange={(e) => {
                          const newAmount = parseInt(e.target.value);
                          setInsightCheckToUpdate({ ...row, amount: newAmount });
                        }}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <Dropdown disabled>
                      <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }} disabled={loading}>
                        <MoreVert />
                      </MenuButton>
                      <Menu placement="bottom-end" sx={{ zIndex: 9999999999 }}>
                        <MenuItem
                          disabled={insightCheckToUpdate?.id === row.id}
                          onClick={() => {
                            setInsightCheckToUpdate(row);
                          }}
                        >
                          <ListItemDecorator>
                            <Edit />
                          </ListItemDecorator>
                          Aanpassen
                        </MenuItem>
                        <MenuItem disabled={insightCheckToUpdate?.id !== row.id} onClick={updateInsightCheck}>
                          <ListItemDecorator>
                            <SaveRoundedIcon />
                          </ListItemDecorator>
                          Opslaan
                        </MenuItem>
                        <ListDivider />
                        <MenuItem
                          variant="soft"
                          color="danger"
                          onClick={() => {
                            onClickDelete(row.id);
                          }}
                        >
                          <ListItemDecorator sx={{ color: 'inherit' }}>
                            <DeleteForever />
                          </ListItemDecorator>
                          Delete
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    </div>
  );
};
