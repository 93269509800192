import { FormControl, FormLabel } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import * as React from 'react';

type ViewTypeRadioGroupProps = {
  onChange: (newValue: ViewTypeRadioGroupOptions) => void;
  value: ViewTypeRadioGroupOptions;
};

export enum ViewTypeRadioGroupOptions {
  checks = 'checks',
  transactionAmounts = 'transactionAmounts',
  invoiceAmounts = 'invoiceAmounts',
}

const ViewTypeRadioGroupOptionsWithLabels = [
  { value: ViewTypeRadioGroupOptions.checks, label: 'Standaard' },
  { value: ViewTypeRadioGroupOptions.transactionAmounts, label: 'Bedragen' },
  // { value: ViewTypeRadioGroupOptions.invoiceAmounts, label: 'Facturen' },
];

export const ViewTypeRadioGroup = (props: ViewTypeRadioGroupProps) => {
  const { onChange } = props;
  return (
    <FormControl>
      <FormLabel>Weergave</FormLabel>
      <Select value={props.value} onChange={(event, newValue) => onChange(newValue as ViewTypeRadioGroupOptions)} sx={{}}>
        {ViewTypeRadioGroupOptionsWithLabels.map((item) => (
          <Option
            key={item.value}
            value={item.value}
            sx={{
              '&:hover': {
                bgcolor: 'background.level1',
              },
            }}
          >
            {item.label}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
};
