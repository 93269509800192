import { UserButton, useUser } from '@clerk/clerk-react';
import BoltIcon from '@mui/icons-material/Bolt';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanRoundedIcon from '@mui/icons-material/LanRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import Person3RoundedIcon from '@mui/icons-material/Person3Rounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMe } from '../../hooks/useMe';
import ColorSchemeToggle from './ColorSchemeToggle';
import DomainSelect from './domainSelect';
import { Toggler } from './toggler';
import { closeSidebar } from './utils';

export default function Sidebar() {
  const user = useUser();
  const [page, setPage] = React.useState('users');
  const navigate = useNavigate();
  const useMeQuery = useMe();

  const dashboardItems = React.useMemo(() => {
    return [
      {
        label: 'Efficiëntie',
        route: '/efficiency/completeness',
        Icon: BoltIcon,
        children: [
          {
            label: 'RGS Volledigheid',
            route: '/efficiency/completeness',
            disabled: false,
          },
          {
            label: 'RGS Consistentie',
            route: '/efficiency/consistency',
            disabled: false,
          },
          {
            label: 'Boekingswijze',
            route: '/efficiency/Boekingswijze',
            disabled: false,
          },
        ],
      },
      {
        label: 'Kwaliteit',
        route: '/completeness',
        Icon: StarRoundedIcon,
        children: [
          {
            label: 'Volledigheid',
            route: '/completeness',
            disabled: false,
          },
          {
            label: 'BTW Attentiepunten',
            route: '/dashboards/vat',
            disabled: false,
          },
          {
            label: 'BTW Rondrekening',
            route: '/dashboards/vat-rounding',
            disabled: false,
          },
        ],
      },
      {
        label: 'Inzicht',
        route: '/insights',
        Icon: BubbleChartRoundedIcon,
        children: [
          {
            label: 'Checks',
            route: '/insights',
            disabled: false,
          },
          {
            label: 'Openstaande posten',
            route: '/dashboards/pending-invoices/incoming',
            disabled: false,
          },
        ],
      },
      {
        label: 'Advies',
        route: '/kia',
        Icon: HandshakeRoundedIcon,
        children: [
          {
            label: 'KIA',
            route: '/kia',
            disabled: false,
          },
          {
            label: 'WKR',
            route: '/kia-dashboard',
            disabled: true,
          },
          {
            label: 'VA-Check',
            route: '/va-check',
            disabled: true,
          },
        ],
      },
      {
        label: 'Taken',
        route: '/tasks',
        Icon: NoteAltRoundedIcon,
        children: [],
      },
    ];
  }, [useMeQuery.data]);

  const adminItems = React.useMemo(() => {
    return [
      {
        label: 'Gebruikers',
        route: '/users',
        Icon: Person3RoundedIcon,
        admin: false,
      },
      {
        label: 'Administraties',
        route: '/administrations',
        Icon: BusinessCenterRoundedIcon,
        admin: false,
      },
      {
        label: 'Koppelingen',
        route: '/integrations',
        Icon: LanRoundedIcon,
        admin: false,
      },
    ];
  }, [useMeQuery.data]);

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />

      {/* Sidebar overlay */}
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      {/* Title and theme toggle */}
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton
          variant="soft"
          color="primary"
          size="sm"
          onClick={() => {
            navigate('/');
          }}
        >
          <BrightnessAutoRoundedIcon />
        </IconButton>
        <Typography level="title-lg">Aida</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>

      {/* Domain selector */}
      <DomainSelect />

      <Divider />

      {/* User items */}
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            'gap': 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {dashboardItems.map((item) => {
            if (item.children.length === 0) {
              return (
                <ListItem key={item.route}>
                  <ListItemButton
                    selected={page === item.route}
                    onClick={() => {
                      navigate(item.route);
                    }}
                  >
                    <item.Icon />
                    <ListItemContent>
                      <Typography level="title-sm">{item.label}</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              );
            }

            return (
              <ListItem nested key={item.route}>
                <Toggler
                  renderToggle={({ open, setOpen }) => (
                    <ListItemButton
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <item.Icon />
                      <ListItemContent>
                        <Typography level="title-sm">{item.label}</Typography>
                      </ListItemContent>
                      <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                    </ListItemButton>
                  )}
                >
                  <List sx={{ gap: 0.5 }}>
                    {item.children.map((child) => {
                      return (
                        <ListItem sx={{ mt: 0.5 }} key={child.route}>
                          <ListItemButton disabled={child.disabled} onClick={() => navigate(child.route)}>
                            {child.label}
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Toggler>
              </ListItem>
            );
          })}
        </List>

        <Divider />

        {/* Admin Items */}
        <List
          size="sm"
          sx={{
            'gap': 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {adminItems.map((item) => (
            <ListItem key={item.label}>
              <ListItemButton
                selected={page === item.route}
                onClick={() => {
                  navigate(item.route);
                }}
              >
                <item.Icon />
                <ListItemContent>
                  <Typography level="title-sm">{item.label}</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Divider />

      {/* Profile button */}
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        <UserButton />
        <div className="flex flex-col">
          <p>{`Hallo, ${user.user?.firstName || user.user?.primaryEmailAddress?.toString().split('@')[0]}!`}</p>
        </div>
      </Box>
    </Sheet>
  );
}
