import { Button, Divider, FormControl, FormLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/joy';
import { useState } from 'react';
import { Check } from '.';
import { DivisionSelect } from '../../../components/divisionSelect';
import { RgsFilters, RgsSelect } from '../../../components/rgsSelectv2';
import useDivisions from '../../../hooks/useDivisions';
import { PostBalanceCheck, postBalanceCheck } from '../../../services/api/insights/postBalanceCheck';
import { BalanceCheckSubForm } from './balanceCheckSubForm';

type InsightsCheckModalFormProps = {
  cancel: () => void;
  selectedCheck: Check | null;
};

export default function Form({ cancel, selectedCheck }: Readonly<InsightsCheckModalFormProps>) {
  const [balanceChecks, setBalanceChecks] = useState<Partial<PostBalanceCheck>[]>([]);
  const [administrationId, setAdministrationId] = useState<string>('');
  const [rgsCode, setRgsCode] = useState<string>('');
  const [applyGlobally, setApplyGlobally] = useState<boolean>(false);
  const [checkType, setCheckType] = useState<'kpi' | 'balance'>('balance');
  const divisionsQuery = useDivisions();
  const [loading, setLoading] = useState(false);

  const handleBalanceCheckChange = (balanceChecks: Partial<PostBalanceCheck>[]) => {
    const newBalanceChecks = balanceChecks.map((check) => {
      return {
        ...check,
        rgsCode,
      };
    });
    setBalanceChecks(newBalanceChecks);
  };

  const handlePostBalanceChecks = async () => {
    setLoading(true);
    const checksToPost: PostBalanceCheck[] = [];

    if (applyGlobally) {
      if (!divisionsQuery.isSuccess) return;
      const administrations = divisionsQuery.data;

      // For each administration, post the balance checks
      administrations.forEach((administration) => {
        balanceChecks.forEach((check) => {
          checksToPost.push({
            administrationId: administration.Code.toString(),
            rgsCode,
            operator: check.operator!,
            amount: check.amount!,
          });
        });
      });
      await postBalanceCheck(checksToPost);
      setLoading(false);
      cancel();
      return;
    }

    if (!applyGlobally) {
      // Post the balance checks for the selected administration
      balanceChecks.forEach((check) => {
        checksToPost.push({
          administrationId,
          rgsCode,
          operator: check.operator!,
          amount: check.amount!,
        });
      });
      await postBalanceCheck(checksToPost);
      setLoading(false);
      cancel();
    }
  };

  const confirm = async () => {
    if (checkType === 'balance') {
      await handlePostBalanceChecks();
      return;
    }

    if (checkType === 'kpi') {
      throw new Error('Function not implemented.');
    }

    throw new Error(`Unknown check type: ${checkType}`);
  };

  const disabled = loading;

  return (
    <Stack direction={'column'} gap={5}>
      <Typography component="h2" id="close-modal-title" level="h4" textColor="inherit" fontWeight="lg">
        Inzicht check aanmaken
      </Typography>

      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid xs={6}>
          <Stack direction={'column'} gap={3}>
            <FormControl>
              <FormLabel>Toepassen op</FormLabel>
              <RadioGroup
                defaultValue="specific"
                name="radio-buttons-group"
                onChange={(event) => {
                  switch (event.target.value) {
                    case 'specific':
                      setApplyGlobally(false);
                      break;
                    case 'global':
                      setApplyGlobally(true);
                      break;
                  }
                }}
              >
                <Radio value="specific" label="Specifieke administratie" variant="soft" />
                <Radio value="global" label="Alle administraties" variant="soft" />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
        <Grid xs={6}>
          {!applyGlobally && (
            <DivisionSelect
              onChange={(division) => {
                setAdministrationId(division);
              }}
              disabled={applyGlobally}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid xs={6}>
          <FormControl>
            <FormLabel>Check type</FormLabel>
            <RadioGroup
              defaultValue="balance"
              name="radio-buttons-group"
              onChange={(event) => {
                switch (event.target.value) {
                  case 'balance':
                    setCheckType('balance');
                    break;
                  case 'kpi':
                    setCheckType('kpi');
                    break;
                }
              }}
            >
              <Radio value="balance" label="RGS Code" variant="soft" />
              <Radio disabled value="kpi" label="Kengetal" variant="soft" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <Stack direction={'column'} gap={1}>
            {checkType === 'balance' && (
              <RgsSelect
                key={'rgs-select'}
                onChange={(rgsCode) => {
                  setRgsCode(rgsCode);
                }}
                value={rgsCode}
                type={RgsFilters.RGS4}
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <Divider />

      {/* Balance check form fields */}
      {checkType === 'balance' && <BalanceCheckSubForm onChange={handleBalanceCheckChange} />}

      <Stack direction={'row'} justifyContent={'space-between'}>
        <Button onClick={cancel} color="neutral" variant="outlined" disabled={disabled}>
          Annuleren
        </Button>
        <Button onClick={confirm} disabled={disabled}>
          {selectedCheck ? 'Bijwerken' : 'Toevoegen'}
        </Button>
      </Stack>
    </Stack>
  );
}
