import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { IconButton, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { getRgsCode } from '../../../assets/rgsCodes';
import { ConfirmationDialog } from '../../../components/confirmationDialog';
import Spinner from '../../../components/dynamicSpinner';
import NoData from '../../../components/noData';
import { useBalanceChecks } from '../../../hooks/insights/useBalanceChecks';
import { useKpiChecks } from '../../../hooks/insights/useKpiChecks';
import { deleteBalanceCheckBulk } from '../../../services/api/insights/deleteBalanceCheckBulk';

const PAGE_SIZE = 15;

type Props = {};

// Combine both queries into common format
type InsightCheckRow = {
  label: string;
  amount: number;
} & (
  | {
      checkType: 'balans';
      rgsCode: string;
    }
  | {
      checkType: 'kpi';
      kpi: string;
    }
);

export default function InsightCheckOverview({}: Props) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<InsightCheckRow | null>(null);
  const [page, setPage] = useState(0);
  const balanceChecksQuery = useBalanceChecks();
  const kpiChecksQuery = useKpiChecks();
  const [loading, setLoading] = useState(false);

  // TODO: Abstract this into a custom hook
  const insightChecksQuery = {
    isLoading: balanceChecksQuery.isLoading || kpiChecksQuery.isLoading,
    data: [
      ...(balanceChecksQuery.data || []).map((check) => {
        const row: InsightCheckRow = {
          label: check.rgsCode,
          checkType: 'balans',
          amount: check.amount,
          rgsCode: check.rgsCode,
        };
        return row;
      }),
      ...(kpiChecksQuery.data || []).map((check) => {
        const row: InsightCheckRow = {
          label: check.kpi,
          checkType: 'kpi',
          amount: check.amount,
          kpi: check.kpi,
        };
        return row;
      }),
    ] as InsightCheckRow[],
    refetch: async () => {
      await balanceChecksQuery.refetch();
      await kpiChecksQuery.refetch();
    },
  };

  const deleteBalanceCheckByRGSCode = async (rgsCode: string) => {
    setLoading(true);
    await deleteBalanceCheckBulk(rgsCode);
    await balanceChecksQuery.refetch();
    setLoading(false);
  };

  if (insightChecksQuery.isLoading) {
    return <Spinner />;
  }

  const rows = insightChecksQuery.data || [];

  return (
    <>
      <ConfirmationDialog
        content="Weet je zeker dat je deze check voor *alle* administraties wilt verwijderen?"
        onClose={() => {
          setConfirmDeleteOpen(false);
        }}
        disabled={loading}
        onConfirm={async () => {
          if (!selectedRow) {
            toast.error(`Er is iets misgegaan, probeer het opnieuw`);
            return;
          }

          if (selectedRow.checkType === 'balans') {
            try {
              await deleteBalanceCheckByRGSCode(selectedRow!.rgsCode);
              setConfirmDeleteOpen(false);
              setSelectedRow(null);
            } catch (error) {
              toast.error(`Er is iets misgegaan, probeer het opnieuw`);
            }
          } else {
            toast.error(`KPI checks kunnen niet verwijderd worden`);
          }
        }}
        open={confirmDeleteOpen}
        title="Check verwijderen"
      />
      <Stack direction={'column'} gap={3}>
        {rows.length > 0 ? (
          <Sheet className="OrderTableContainer" variant="outlined">
            <Table aria-label="basic table" stickyHeader stickyFooter>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '6rem',
                    }}
                  >
                    Check type
                  </th>
                  <th>RGS Code / kengetal</th>
                  <th>Beschrijving</th>
                  <th>#Administraties toegepast</th>
                  <th
                    style={{
                      width: '4rem',
                    }}
                  >
                    Actie
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
                  return (
                    <tr key={JSON.stringify(row)}>
                      <td>{row.checkType}</td>
                      <td>{row.label}</td>
                      <td>{getRgsCode(row.label)?.descriptionShort ?? '-'}</td>
                      <td>{row.amount}</td>
                      <td>
                        <IconButton
                          disabled={loading || row.checkType === 'kpi'}
                          onClick={() => {
                            if (row.checkType === 'balans') {
                              setSelectedRow(row);
                              setConfirmDeleteOpen(true);
                            }
                          }}
                        >
                          <DeleteRoundedIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Sheet>
        ) : (
          <Box style={{ padding: '5rem 10rem' }}>
            <NoData />
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
        </Box>
      </Stack>
    </>
  );
}
