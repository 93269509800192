import { useQuery } from '@tanstack/react-query';
import { getPendingInvoices, GetOustandingInvoicesParams } from '../services/api/getPendingInvoices';
import queryTypes from '../types/hookTypes';

const useHook = (params: GetOustandingInvoicesParams) => {
  return useQuery([queryTypes.outstandingReceivables, JSON.stringify(params)], async () => {
    const response = await getPendingInvoices(params);
    return response;
  });
};

export default useHook;
