import MoreVert from '@mui/icons-material/MoreVert';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Stack from '@mui/joy/Stack';
import { useMemo } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import toast from 'react-hot-toast';
import DivisionSearch from '../../components/divisionSearch';
import KpiCard from '../../components/KpiCard';
import usePendingInvoices from '../../hooks/usePendingInvoices';
import { formatter } from '../../translations/numberFormatter';
import { exportDataToExcel } from './export';
import { PendingInvoicesTable } from './PendingInvoicesTable';

export type PendingInvoicesDashboardProps = {
  lineType: string;
  label: string;
};

export const PendingReceivablesDashboard = (props: PendingInvoicesDashboardProps) => {
  const { lineType, label } = props;
  const outstandingReceivablesQuery = usePendingInvoices({
    lineType,
  });

  const totalPendingInvoices = useMemo(() => {
    if (!outstandingReceivablesQuery.data) {
      return 0;
    }

    return outstandingReceivablesQuery.data.reduce((acc, row) => {
      return acc + row.unpaidInvoices;
    }, 0);
  }, [outstandingReceivablesQuery.data]);

  const totalOutstandingAmount = useMemo(() => {
    if (!outstandingReceivablesQuery.data) {
      return 0;
    }

    return outstandingReceivablesQuery.data.reduce((acc, row) => {
      return acc + -row.totalAmount;
    }, 0);
  }, [outstandingReceivablesQuery.data]);

  const averageOutstandingAmount = useMemo(() => {
    if (!outstandingReceivablesQuery.data) {
      return 0;
    }

    return totalOutstandingAmount / totalPendingInvoices;
  }, [outstandingReceivablesQuery.data]);

  const onClickExport = () => {
    if (!outstandingReceivablesQuery.data) {
      toast.error('Geen data om te exporteren');
      return;
    }

    exportDataToExcel(outstandingReceivablesQuery.data, {
      fileName: `openstaande-${label}-facturen.xlsx`,
      sheetName: `openstaande-${label}-facturen`,
    });
  };

  return (
    <Stack direction="column" gap={3}>
      <Stack direction={'column'} gap={3}>
        <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'end'}>
          <DivisionSearch />

          <Dropdown>
            <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}>
              <MoreVert />
            </MenuButton>
            <Menu>
              <MenuItem onClick={onClickExport}>Export</MenuItem>
            </Menu>
          </Dropdown>
        </Stack>

        <Stack gap={3} direction={'row'}>
          <KpiCard
            key={`total_pending_invoices`}
            value={new Intl.NumberFormat('nl-NL').format(totalPendingInvoices).toString()}
            label={`Openstaande ${label} facturen (ouder dan 2 maanden)`}
            onClick={() => {}}
          />
          <KpiCard
            key={`total_pending_invoices_amount`}
            value={formatter.format(totalOutstandingAmount)}
            label={'Totaal openstaand bedrag'}
            onClick={() => {}}
          />
          <KpiCard
            key={`average_pending_invoices_amount`}
            value={formatter.format(averageOutstandingAmount)}
            label={'Gemiddeld openstaand bedrag'}
            onClick={() => {}}
          />
        </Stack>

        <PendingInvoicesTable lineType={lineType} label={label} />
      </Stack>
    </Stack>
  );
};
