import client from '../../utils/axiosClient';

export type OutstandingInvoices = {
  division: string;
  name: string;
  unpaidInvoices: number;
  totalAmount: number;
};
export type GetOustandingInvoicesParams = {
  lineType: string;
};

export const getPendingInvoices = async (params: GetOustandingInvoicesParams) => {
  const response = await client.get<OutstandingInvoices[]>('/api/receivables/outstanding', { params });
  return response.data;
};

type PendingInvoicesPerAdministration = {
  invoiceNumber: string;
  accountName: string;
  invoiceAmount: number;
  description: string;
  invoiceDate: string;
};

export type getPendingInvoicesPerAdministrationParams = {
  lineType: string;
  administrationId: string;
};

export const getPendingInvoicesPerAdministration = async (params: getPendingInvoicesPerAdministrationParams) => {
  const response = await client.get<PendingInvoicesPerAdministration[]>(`/api/receivables/outstanding/administration`, { params });
  return response.data;
};
