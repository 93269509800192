import { useQuery } from '@tanstack/react-query';
import { getPendingInvoicesPerAdministration, getPendingInvoicesPerAdministrationParams } from '../services/api/getPendingInvoices';
import queryTypes from '../types/hookTypes';

const useHook = (params: getPendingInvoicesPerAdministrationParams) => {
  return useQuery([queryTypes.usePendingInvoicesPerAdministration, JSON.stringify(params)], async () => {
    const response = await getPendingInvoicesPerAdministration(params);
    return response;
  });
};

export default useHook;
